import { gql } from 'graphql-tag'

export const ISSUE_TRANSACTION = gql`mutation IssueTransaction($data: IssueTransactionInput!) {
  issueTransaction(data: $data) {
    id
    transactionID
    termID
    type
    issuedDate
    effectiveDate
    annualPremiumCents
    proratedPremiumCents
    diffs
    isReversed
    reversedBy
  }
}`

export const UPDATE_CANCELLATION_TRANSACTION = gql`mutation UpdateCancellationTransaction($data: UpdateCancellationTransactionInput!) {
  updateCancellationTransaction(data: $data) {
    id
    termID
    transactionID
    transactionEffectiveDate
    cancellationInfo {
      reason
      subreason
      type
    }
    annualPremiumCents,
    proratedPremiumCents
  }
}`

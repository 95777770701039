import { gql } from 'graphql-tag'

export const GET_DASHBOARD_SEARCH_RESULTS = gql`query Query($input: DashboardSearchResultsInput!) {
  dashboardSearchResults(input: $input) {
    results {
      id
      termID
      transactionID
      agencyID
      agencyName
      assigneeID
      assigneeName
      number
      applicantFirstName
      applicantMiddleName
      applicantLastName
      locationStreetNumber
      locationStreetName
      locationUnit
      locationCity
      locationState
      locationZip
      issued
      termEffectiveDate
      primaryUseLabel
      premiumTotalCents
      totalHearts
      status
      secondaryStatus
    }
    hasMore
  }
}`

export const mapStateValueToQuestion = (value) => {
  switch (value) {
    case 'isImmediateAssistanceNeeded': return 'Do you need immediate assistance or help to secure the home?'
    case 'hasHolesInDoorsWindows': return 'Are there any holes in the doors or windows which need to be covered?'
    case 'wasRoofDamaged': return 'Was the roof damaged?'
    case 'hasResultingWaterDamage': return 'Was there resulting water damage?'
    case 'didLosePower': return 'Did you lose power?'
    case 'hasStandingWater': return 'Is there currently standing/pooled water or saturated flooring in the house?'
    case 'wasHomeBrokenInto': return 'Did someone break into your home?'
    case 'isHomeSafe': return 'Are you able to live at home safely?'
    case 'isWaterTurnedOff': return 'Has the water source been turned off?'
    case 'didTurnoffStopWater': return 'Did this stop the water?'
    case 'didContactPlumber': return 'Have you contacted a plumber?'
    case 'didAdviseOnPlumber': return 'I have advised the customer to contact a plumber'
    case 'didFireDepartmentCome': return 'Did the fire department come to the house?'
    case 'injuredHasAttorney': return 'Is the injured person represented by an attorney?'
    case 'ownerHasAttorney': return 'Is the property owner represented by an attorney?'
    case 'isLawsuitFiled': return 'Has a lawsuit been filed/served?'
    case 'werePoliceNotified': return 'Were the police notified/involved?'
    case 'wasReportFiled': return 'Was a police report filed?'
    case 'wasArrestMade': return 'Was an arrest made?'
    case 'didReceiveMedicalTreatment': return 'Did the injured person receive medical care/treatment?'
    case 'descriptionOfTreatment': return 'What treatment was received?'
    case 'didContactMitigation': return 'Have you contacted any companies to help with the clean up?'
    case 'hasPropertyOwnerInformation': return 'Do you have the property owner’s information?'
    case 'didIncidentOccurAtProperty': return 'Did the incident occur at the property address?'
    case 'hasInjuredPersonsInformation': return 'Do you have the injured person’s information?'
    default: return 'NA'
  }
}

export const getBaseAdditionalDetailState = () => {
  return {
    isImmediateAssistanceNeeded: null,
    hasHolesInDoorsWindows: null,
    wasRoofDamaged: null,
    hasResultingWaterDamage: null,
    didLosePower: null,
    hasStandingWater: null,
    wasHomeBrokenInto: null,
    isHomeSafe: null,
    isWaterTurnedOff: null,
    didTurnoffStopWater: null,
    didContactPlumber: null,
    didAdviseOnPlumber: null,
    didFireDepartmentCome: null,
    injuredHasAttorney: null,
    ownerHasAttorney: null,
    isLawsuitFiled: null,
    werePoliceNotified: null,
    wasReportFiled: null,
    wasArrestMade: null,
    didReceiveMedicalTreatment: null,
    descriptionOfTreatment: '',
    didContactMitigation: null,
    mitigationContactIds: [],
    hasPropertyOwnerInformation: null,
    propertyOwnerContactIds: [],
    didIncidentOccurAtProperty: null,
    incidentLocation: {
      description: '',
      streetLineOne: '',
      streetLineTwo: '',
      city: '',
      region: '',
      postalCode: '',
    },
    hasInjuredPersonsInformation: null,
    injuredPersonContactIds: [],
  }
}

export const orderedQuestions = [
  'isWaterTurnedOff',
  'didTurnoffStopWater',
  'didContactPlumber',
  'didAdviseOnPlumber',
  'hasStandingWater',
  'wasSmokeMainCauseOfDamage',
  'wasRoofDamaged',
  'didFireDepartmentCome',
  'hasResultingWaterDamage',
  'hasHolesInDoorsWindows',
  'wasHomeBrokenInto',
  'didIncidentOccurAtProperty',
  'incidentLocation',
  'werePoliceNotified',
  'wasReportFiled',
  'wasArrestMade',
  'didReceiveMedicalTreatment',
  'descriptionOfTreatment',
  'ownerHasAttorney',
  'injuredHasAttorney',
  'isLawsuitFiled',
  'isHomeSafe',
  'didLosePower',
  'isImmediateAssistanceNeeded',
  'didContactMitigation',
  'mitigationCompanyName',
  'hasInjuredPersonsInformation',
  'injuredDetails',
  'hasPropertyOwnerInformation',
  'propertyOwnerDetails',
  'propertyOwnerContactIds',
  'injuredPersonContactIds',
]

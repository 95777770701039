import { gql } from 'graphql-tag'

export const UPDATE_AGENT_ON_RECORD = gql`mutation Mutation($data: UpdateAgentOfRecordInput!) {
  updateAgentOfRecord(data: $data) {
    id
    number
    transactionID
    termID
    agent {
      id
      firstName
      lastName

    }
  }
}`

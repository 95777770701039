import { showError } from 'nuxt/app'
import validators from '~/apollo/error-validators'

export default (errorContext, nuxtApp) => {
  const { $bugsnag, params, $store } = nuxtApp

  const { public: config } = useRuntimeConfig()

  const validErrors = errorContext.graphQLErrors.filter(el => el ?? false)
  for (const gqlError of validErrors) {
    for (const validator of validators) {
      const result = validator(gqlError, config, params)

      if (!result.valid) { continue }

      if (result.redirect) {
        showError({
          data: result.errorContext,
        })
      }

      return $store.commit('error/displayErrorModal', result.errorContext)
    }
  }

  // We haven't caught the error in a validator, log the unknown issue to Bugsnag
  const operationName = errorContext.operation?.operationName ?? 'UNSPECIFIED_OPERATION'

  const errorMessage = `Apollo Error Handler: Encountered error during operation ${operationName}`

  $bugsnag.notify(new Error(errorMessage), (event) => {
    event.context = `${event.context} | ${operationName}`

    event.addMetadata('GraphQL Context', {
      operation: {
        operationName,
        variables: errorContext.operation?.variables,
        body: errorContext.operation?.query?.loc?.source?.body,
      },
      response: errorContext.response,
    })
  })

  $store.commit('error/displayErrorModal')
}

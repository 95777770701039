export const cancellationReasons = [
  {
    value: 'CancellationInsuredRequest',
    label: 'Insured Request',
  },
  {
    value: 'CancellationUW',
    label: 'Underwriting',
  },
  {
    value: 'CancellationNonPay',
    label: 'Non-Pay',
  },
  {
    value: 'CancellationRewrite',
    label: 'Rewrite',
  },
  {
    value: 'CancellationAgentError',
    label: 'Agent Error',
  },
  {
    value: 'CancellationNotTaken',
    label: 'Not Taken',
  },
]

export const cancellationSubReasons = [
  {
    value: 'CancellationDateOfBirth',
    label: 'Wrong Date of Birth',
  },
  {
    value: 'CancellationWrongAddress',
    label: 'Wrong Address',
  },
  {
    value: 'CancellationUsageType',
    label: 'Usage Type',
  },
  {
    value: 'CancellationNoHit',
    label: 'No Hit',
  },
  {
    value: 'CancellationEffectiveDateRequest',
    label: 'Effective Date Request',
  },
  {
    value: 'CancellationInspectionConcerns',
    label: 'Inspection Concerns',
  },
  {
    value: 'CancellationVacant',
    label: 'Vacant',
  },
  {
    value: 'CancellationNamedInsuredDeceased',
    label: 'Named Insured Deceased',
  },
  {
    value: 'CancellationLossExperience',
    label: 'Loss History',
  },
  {
    value: 'CancellationTerminationOfAgency',
    label: 'Termination of Agency',
  },
  {
    value: 'CancellationRefusedInspection',
    label: 'Lack of Cooperation',
  },
  {
    value: 'CancellationUWEligibility',
    label: 'Other Underwriting Eligibility',
  },
  {
    value: 'CancellationMaterialMisrepresentation',
    label: 'Material Misrepresentation or Fraud',
  },
  {
    value: 'CancellationChangeInRisk',
    label: 'Substantial Change in Risk',
  },
  {
    value: 'CancellationNoPaymentMade',
    label: 'No Payment Made',
  },
  {
    value: 'CancellationNoSignedApplication',
    label: 'No Signed Application',
  },
  {
    value: 'CancellationPropertySold',
    label: 'Property Sold',
  },
  {
    value: 'CancellationClosingFellThrough',
    label: 'Closing Fell Through',
  },
  {
    value: 'CancellationCoverageElsewhere',
    label: 'Coverage Elsewhere',
  },
  {
    value: 'CancellationRenewalNotTaken',
    label: 'Renewal Not Taken',
  },
  {
    value: 'CancellationOther',
    label: 'Other',
  },
]

export const cancellationTypes = [
  {
    value: 'UWNewBusinessCancel',
    label: 'New Business Cancel',
    subtext: 'Any cancellation during the underwriting review period.',
  },
  {
    value: 'UWMidTermCancel',
    label: 'Mid Term Cancel',
    subtext: 'Any cancellation outside of the underwriting review period and before renewal.',
  },
  {
    value: 'UWFlatCancel',
    label: 'Flat Cancel',
    subtext: 'Cancellation takes place on day zero.',
  },
]

import { gql } from 'graphql-tag'

export const COMMUNICATION_PREFERENCES_AND_DISCOUNTS = gql`query communicationPreferences($policyNumber: String!) {
  policy(number: $policyNumber) {
    id
    communicationPreferences {
      id
      allowSMS
      email
      phone
    }
    todaysActiveTransaction {
      id
      discounts {
        optedIntoEdocs
      }
      primaryContact {
        humanNamedInsured {
          firstName
          lastName
          streetLine1
          streetLine2
          city
          state
          zip
        }
        legalEntityNamedInsured {
          name
          streetLine1
          streetLine2
          city
          state
          zip
        }
      }
    }
  }
}`

export const NOTES = gql`query Notes($policyNumber: String!) {
  notes(number: $policyNumber) {
    id
    title
    content
    updatedAt
    updatedBy {
      firstName
      lastName
    }
  }
}`

import { gql } from 'graphql-tag'

// Mortgagee
export const ADD_MORTGAGEE = gql`mutation AddMortgageeToTransaction($data: AddMortgageeToTransactionInput!) {
  addMortgageeToTransaction(data: $data) {
    id
    mortgagees {
      name
      streetLine1
      streetLine2
      city
      state
      zipCode
      plusFourCode
      loanNumber
      fax
      email
      interest
    }
    billingMortgagee {
      name,
      streetLine1,
      streetLine2,
      city,
      state,
      zipCode,
      plusFourCode,
      loanNumber,
      fax,
      email
    }
  }
}`

export const REMOVE_MORTGAGEE = gql`mutation RemoveMortgageeFromTransaction($data: RemoveMortgageeFromTransactionInput!) {
  removeMortgageeFromTransaction(data: $data) {
    id
    mortgagees {
      name
      streetLine1
      streetLine2
      city
      state
      zipCode
      plusFourCode
      loanNumber
      fax
      email
      interest
    }
    billingMortgagee {
      name,
      streetLine1,
      streetLine2,
      city,
      state,
      zipCode,
      plusFourCode,
      loanNumber,
      fax,
      email
    }
  }
}`

export const UPDATE_MORTGAGEE = gql`mutation UpdateMortgageeOnTransaction($data: UpdateMortgageeOnTransactionInput!) {
  updateMortgageeOnTransaction(data: $data) {
    id
    mortgagees {
      name
      streetLine1
      streetLine2
      city
      state
      zipCode
      plusFourCode
      loanNumber
      fax
      email
      interest
    }
    billingMortgagee {
      name,
      streetLine1,
      streetLine2,
      city,
      state,
      zipCode,
      plusFourCode,
      loanNumber,
      fax,
      email
    }
  }
}`

export const UPDATE_BILLING_MORTGAGEE = gql`mutation UpdateBillingMortgagee($data: UpdatePaymentMethodInput!) {
  updatePaymentMethod(data: $data) {
   id
   paymentMethod
  }
}`

// Billing
export const SAVE_PAYMENT_METHOD = gql`mutation UpdatePaymentMethod($data: UpdatePaymentMethodInput!) {
  updatePaymentMethod(data: $data) {
    id,
    paymentMethod,
    mortgagees {
      name
      streetLine1
      streetLine2
      city
      state
      zipCode
      plusFourCode
      loanNumber
      fax
      email
      interest
    }
    billingMortgagee {
      name,
      streetLine1,
      streetLine2,
      city,
      state,
      zipCode,
      plusFourCode,
      loanNumber,
      fax,
      email
    }
  }
}`

// Human Named Insured
export const ADD_HUMAN_NAMED_INSURED_TO_TRANSACTION = gql`mutation addHumanNamedInsuredToTransaction($data: AddHumanNamedInsuredToTransactionInput!) {
  addHumanNamedInsuredToTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    humanNamedInsureds {
      firstName
      middleName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
  }
}`

export const UPDATE_HUMAN_NAMED_INSURED_ON_TRANSACTION = gql`mutation updateHumanNamedInsuredOnTransaction($data: UpdateHumanNamedInsuredOnTransactionInput!) {
  updateHumanNamedInsuredOnTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    humanNamedInsureds {
      firstName
      middleName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
  }
}`

export const REMOVE_HUMAN_NAMED_INSURED_FROM_TRANSACTION = gql`mutation removeHumanNamedInsuredFromTransaction($data: RemoveHumanNamedInsuredFromTransactionInput!) {
  removeHumanNamedInsuredFromTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    humanNamedInsureds {
      firstName
      middleName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
  }
}`

// Legal Entity Named Insured
export const ADD_LEGAL_ENTITY_NAMED_INSURED_TO_TRANSACTION = gql`mutation addLegalEntityNamedInsuredToTransaction($data: AddLegalEntityNamedInsuredToTransactionInput!) {
  addLegalEntityNamedInsuredToTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    legalEntityNamedInsureds {
      name
      type
      earnsOtherRevenue
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
  }
}`

export const UPDATE_LEGAL_ENTITY_NAMED_INSURED_ON_TRANSACTION = gql`mutation updateLegalEntityNamedInsuredOnTransaction($data: UpdateLegalEntityNamedInsuredOnTransactionInput!) {
  updateLegalEntityNamedInsuredOnTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    legalEntityNamedInsureds {
      name
      type
      earnsOtherRevenue
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
  }
}`

export const REMOVE_LEGAL_ENTITY_NAMED_INSURED_FROM_TRANSACTION = gql`mutation removeLegalEntityNamedInsuredFromTransaction($data: RemoveLegalEntityNamedInsuredFromTransactionInput!) {
  removeLegalEntityNamedInsuredFromTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    legalEntityNamedInsureds {
      name
      type
      earnsOtherRevenue
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
  }
}`

// Non Resident Trustee
export const ADD_NON_RESIDENT_TRUSTEE_TO_TRANSACTION = gql`mutation addNonResidentTrusteeToTransaction($data: AddNonResidentTrusteeToTransactionInput!) {
  addNonResidentTrusteeToTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    nonResidentTrustees {
      firstName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
    }
  }
}`

export const UPDATE_NON_RESIDENT_TRUSTEE_ON_TRANSACTION = gql`mutation updateNonResidentTrusteeOnTransaction($data: UpdateNonResidentTrusteeOnTransactionInput!) {
  updateNonResidentTrusteeOnTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    nonResidentTrustees {
      firstName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
    }
  }
}`

export const REMOVE_NON_RESIDENT_TRUSTEE_FROM_TRANSACTION = gql`mutation removeNonResidentTrusteeFromTransaction($data: RemoveNonResidentTrusteeFromTransactionInput!) {
  removeNonResidentTrusteeFromTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    nonResidentTrustees {
      firstName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
    }
  }
}`

// Other Household Member
export const ADD_OTHER_HOUSEHOLD_MEMBER_TO_TRANSACTION = gql`mutation addOtherHouseholdMemberToTransaction($data: AddOtherHouseholdMemberToTransactionInput!) {
  addOtherHouseholdMemberToTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    otherHouseholdMembers {
      firstName
      lastName
      birthdate
      relationship
    }
  }
}`

export const UPDATE_OTHER_HOUSEHOLD_MEMBER_ON_TRANSACTION = gql`mutation updateOtherHouseholdMemberOnTransaction($data: UpdateOtherHouseholdMemberOnTransactionInput!) {
  updateOtherHouseholdMemberOnTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    otherHouseholdMembers {
      firstName
      lastName
      birthdate
      relationship
    }
  }
}`

export const REMOVE_OTHER_HOUSEHOLD_MEMBER_FROM_TRANSACTION = gql`mutation removeOtherHouseholdMemberFromTransaction($data: RemoveOtherHouseholdMemberFromTransactionInput!) {
  removeOtherHouseholdMemberFromTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    otherHouseholdMembers {
      firstName
      lastName
      birthdate
      relationship
    }
  }
}`

// Additional Insureds
export const ADD_ADDITIONAL_INSURED_TO_TRANSACTION = gql`mutation addAdditionalInsuredToTransaction($data: AddAdditionalInsuredToTransactionInput!) {
  addAdditionalInsuredToTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    additionalInsureds {
      firstName
      lastName
      streetLine1
      streetLine2
      city
      state
      zip
      description
    }
  }
}`

export const UPDATE_ADDITIONAL_INSURED_ON_TRANSACTION = gql`mutation updateAdditionalInsuredOnTransaction($data: UpdateAdditionalInsuredOnTransactionInput!) {
  updateAdditionalInsuredOnTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    additionalInsureds {
      firstName
      lastName
      streetLine1
      streetLine2
      city
      state
      zip
      description
    }
  }
}`

export const REMOVE_ADDITIONAL_INSURED_FROM_TRANSACTION = gql`mutation removeAdditionalInsuredFromTransaction($data: RemoveAdditionalInsuredFromTransactionInput!) {
  removeAdditionalInsuredFromTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    additionalInsureds {
      firstName
      lastName
      streetLine1
      streetLine2
      city
      state
      zip
      description
    }
  }
}`

export const ADD_ADDITIONAL_INTEREST_TO_TRANSACTION = gql`mutation addAdditionalInterestToTransaction($data: AddAdditionalInterestToTransactionInput!) {
  addAdditionalInterestToTransaction(data: $data) {
    id
    termID
    termEffectiveDate
    additionalInterests {
      name
      streetLine1
      streetLine2
      city
      state
      zip
      description
    }
  }
}`

export const UPDATE_ADDITIONAL_INTEREST_ON_TRANSACTION = gql`mutation updateAdditionalInterestOnTransaction($data: UpdateAdditionalInterestOnTransactionInput!) {
    updateAdditionalInterestOnTransaction(data: $data) {
      id
      termID
      termEffectiveDate
      additionalInterests {
        name
        streetLine1
        streetLine2
        city
        state
        zip
        description
      }
    }
  }`

export const REMOVE_ADDITIONAL_INTEREST_FROM_TRANSACTION = gql`mutation removeAdditionalInterestFromTransaction($data: RemoveAdditionalInterestFromTransactionInput!) {
    removeAdditionalInterestFromTransaction(data: $data) {
      id
      termID
      termEffectiveDate
      additionalInterests {
        name
        streetLine1
        streetLine2
        city
        state
        zip
        description
      }
    }
  }`

export const ADD_THIRD_PARTY_DESIGNEE_TO_TRANSACTION = gql`mutation addThirdPartyDesigneeToTransaction($data: addThirdPartyDesigneeToTransactionInput!) {
    addThirdPartyDesigneeToTransaction(data: $data) {
      id
      termID
      termEffectiveDate
      thirdPartyDesignees {
        name
        streetLine1
        streetLine2
        city
        state
        zip
      }
    }
  }`

export const UPDATE_THIRD_PARTY_DESIGNEE_ON_TRANSACTION = gql`mutation updateThirdPartyDesigneeOnTransaction($data: UpdateThirdPartyDesigneeOnTransactionInput!) {
    updateThirdPartyDesigneeOnTransaction(data: $data) {
      id
      termID
      termEffectiveDate
      thirdPartyDesignees {
        name
        streetLine1
        streetLine2
        city
        state
        zip
      }
    }
  }`

export const REMOVE_THIRD_PARTY_DESIGNEE_FROM_TRANSACTION = gql`mutation removeThirdPartyDesigneeFromTransaction($data: RemoveThirdPartyDesigneeFromTransactionInput!) {
    removeThirdPartyDesigneeFromTransaction(data: $data) {
      id
      termID
      termEffectiveDate
      thirdPartyDesignees {
        name
        streetLine1
        streetLine2
        city
        state
        zip
      }
    }
  }`

export const APPOINT_POLICY_MAILING_ADDRESS = gql`mutation appointPrimaryContact($data: AppointPrimaryContactInput!) {
  appointPrimaryContact(data: $data) {
    id
    termID
    transactionID
    humanNamedInsureds {
      isPrimaryContact
      birthdate
      firstName
      middleName
      lastName
      streetLine1
      streetLine2
      city
      state
      zip
    }
    legalEntityNamedInsureds {
      isPrimaryContact
      type
      earnsOtherRevenue
      name
      streetLine1
      streetLine2
      city
      state
      zip
    }
  }
}`

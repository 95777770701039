const createPartyWithState = function(party = {}, existingPartyTitleCallback, buttons, isNew = false) {
  return {
    party,
    isNew,
    isLoading: false,
    buttons,
    existingPartyTitle: existingPartyTitleCallback(party),
  }
}

export default (textConfigs, formComponent, api, canAddDelete, canUpdate, maxAllowed) => {
  const {
    title,
    subTitle,
    newPartyTitle,
    createTitle,
    articleId,
    existingPartyTitleCallback,
  } = textConfigs

  const partiesState = {
    title,
    subTitle,
    newPartyTitle,
    createTitle,
    articleId,
    formComponent,
    api,
    editingIndex: null,
    parties: [],
    maxAllowed,
    canAddDelete,
    canUpdate,
  }

  const buttons = [
    {
      title: 'Edit',
      eventName: 'edit',
      isLoading: false,
      display: canUpdate,
    },
    {
      title: 'Delete',
      eventName: 'delete',
      isLoading: false,
      display: canAddDelete,
    },
  ]

  partiesState.addParty = () => {
    if (maxAllowed && partiesState?.parties?.length >= maxAllowed) {
      return
    }

    if (!partiesState.parties) {
      partiesState.parties = []
    }

    partiesState.parties.push(createPartyWithState({}, existingPartyTitleCallback, buttons, true))
  }

  partiesState.edit = (index) => {
    partiesState.editingIndex = index
  }

  partiesState.cancel = () => {
    if (
      (partiesState.editingIndex || partiesState.editingIndex === 0) &&
      partiesState.parties[partiesState.editingIndex]?.isNew
    ) {
      partiesState.parties.splice(partiesState.editingIndex, 1)
    }

    partiesState.editingIndex = null
  }

  partiesState.save = async(partyState, updatedParty) => {
    partyState.isLoading = true

    try {
      if (partyState.isNew) {
        await partiesState.api.create(updatedParty)
      } else {
        await partiesState.api.update(partyState.party, updatedParty)
      }
    } finally {
      partyState.isLoading = false
    }

    partiesState.editingIndex = null
  }

  partiesState.delete = async(partyState) => {
    const deleteButtonState = partyState.buttons.find(button => button.eventName === 'delete')

    deleteButtonState.isLoading = true

    try {
      await partiesState.api.delete(partyState.party)
    } finally {
      deleteButtonState.isLoading = false
    }
  }

  partiesState.load = (parties) => {
    if (!parties?.length) {
      partiesState.parties = []
      return
    }

    partiesState.parties = parties.map(party => createPartyWithState(party, existingPartyTitleCallback, buttons))
  }

  return partiesState
}

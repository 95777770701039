import { POLICY_NOT_FOUND } from '~/apollo/error-codes'

function isIDError(gqlError) {
  const code = gqlError.extensions?.code
  if (!code) { return { valid: false } }

  return {
    valid: code === POLICY_NOT_FOUND,
    redirect: true,
    errorContext: {
      message: 'The policy you\'re trying to reach does not exist.',
    },
  }
}

//
// Removing this code for now. This will be addressed once a backend fix is in place to
// properly report when a policy is not issued versus not available at all.
//
// function isPolicyIsNotIssuedError(gqlError, config, params) {
//   const code = gqlError.extensions?.code
//   if (!code) { return { valid: false } }

//   return {
//     valid: code === POLICY_NOT_ISSUED,
//     redirect: true,
//     errorContext: {
//       message: 'The policy you\'re trying to reach is not issued.',
//       buttonUrl: `${config.legacyPortalDomain}/agents/dashboard?number=${params.number}`,
//       buttonText: 'Return to Policy',
//     },
//   }
// }

function isFromCore(gqlError) {
  const source = gqlError.extensions?.source
  if (!source) { return { valid: false } }

  return {
    valid: source === 'CORE',
    redirect: false,
  }
}

export default [
  isIDError,
  // This must go below all core errors that we want a specific response for
  isFromCore,
]

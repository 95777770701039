import { gql } from 'graphql-tag'

export const UPDATE_COMMUNICATION_PREFERENCES = gql`mutation updateCommunicationPreferencesOnPolicy($data: UpdateCommunicationPreferencesOnPolicyInput!) {
  updateCommunicationPreferencesOnPolicy(data: $data) {
    id
    allowSMS
    email
    phone
  }
}`

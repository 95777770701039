import { gql } from 'graphql-tag'

export const GET_ALL_AGENCY_AGENTS = gql`query Query($agencyId: String!) {
  agency(agencyID: $agencyId) {
    id
    users {
      id
      firstName
      lastName
      isActive
      states
    }
  }
}`

export const GET_NON_RENEWALS = gql`query nonRenewals($agencyId: String!, $agentId: String, $limit: Int, $offset: Int) {
  nonRenewals(agencyID: $agencyId, agentID: $agentId, limit: $limit, offset: $offset) {
    hasMore
    results {
      agentId
      currentPremiumTotalCents
      expirationDate
      namedInsuredLine1
      namedInsuredLine2
      internalNonRenewalReason
      externalNonRenewalReasons
      policyNumber
      propertyAddressLines
    }
  }
}`

import { gql } from 'graphql-tag'

export const GET_POLICY = gql`query PolicyOverview($number: String!, $orderBy: allowedTransactionOrderBy) {
  policy(number: $number) {
    agency {
      id
      name
      phone
      shortID
    }
    id
    number
    transactions(orderBy: $orderBy) {
      transactionID
    },
    currentTermLastActiveTransaction {
      id
      location {
        state
      }
      transactionID
      termID
      termEffectiveDate
      termExpirationDate
      applicant {
        firstName
        middleName
        lastName
      }
      agent {
        firstName
        lastName
        id
      }
      transactionEffectiveDate
      annualPremiumCents
      proratedPremiumCents
    }
    lineageData {
      lineageUUID
      hasLinkedPolicies
      previousLineagePolicy {
        policyID
        policyNumber
        underwritingCompany
        earliestTermEffectiveDate
        latestTermExpirationDate
      }
      followingLineagePolicy {
        policyID
        policyNumber
        underwritingCompany
        earliestTermEffectiveDate
        latestTermExpirationDate
      }
    }
  }
}`

export const GET_CANCELLATION_TRANSACTION = gql`query transaction($policyNumber: String!, $termId: Int!, $transactionId: Int!) {
  transaction(policyNumber: $policyNumber, termID: $termId, transactionID: $transactionId) {
    id
    transactionEffectiveDate
    cancellationInfo {
      reason
      subreason
      type
    }
    annualPremiumCents,
    proratedPremiumCents
  }
}`

export const GET_TRANSACTION_INFO = gql`query Transaction($number: String!, $termId: Int!, $transactionId: Int!) {
  transaction(policyNumber: $number, termID: $termId, transactionID: $transactionId) {
    id
    effectiveDate
    issued
    diffs
    agent {
      id
      email
      firstName
      lastName
      isActive
      roles
      states
    }
  }
}`

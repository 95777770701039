import { default as authenticateEQDX3yONJFMeta } from "/vercel/path1/pages/authenticate.vue?macro=true";
import { default as eligible_45quotesojKzolFuAyMeta } from "/vercel/path1/pages/eligible-quotes.vue?macro=true";
import { default as indexJ7d6BfZ2vfMeta } from "/vercel/path1/pages/non-renewals/index.vue?macro=true";
import { default as queriesceuKTTyZj1Meta } from "/vercel/path1/pages/non-renewals/queries.js?macro=true";
import { default as pinguATbf1KJ9FMeta } from "/vercel/path1/pages/ping.vue?macro=true";
import { default as mutationsAU8dzzcWSNMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/mutations.js?macro=true";
import { default as queriesZAVqeQFWbfMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/queries.js?macro=true";
import { default as reviewg7b80jytSkMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/review.vue?macro=true";
import { default as update2E6og3zUe1Meta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/update.vue?macro=true";
import { default as agentOfRecordYlnKY4QwuXMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord.vue?macro=true";
import { default as cancellationContextlvZzxAH8RSMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/cancellationContext.js?macro=true";
import { default as index3cIVfKwk2mMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/index.vue?macro=true";
import { default as reviewWSdyAcSmOIMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/review.vue?macro=true";
import { default as cancellationYsqHMa69P4Meta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation.vue?macro=true";
import { default as billingwnf8kXLpaLMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/billing.vue?macro=true";
import { default as mortgagee0xy2JsaZYNMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mortgagee.vue?macro=true";
import { default as mutationsdhkCvBB6JlMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mutations.js?macro=true";
import { default as partiesIM3C33wVdFMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/parties.vue?macro=true";
import { default as partiesStateFactory1075NKa2DOMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/partiesStateFactory.js?macro=true";
import { default as queriesM0NKHxmJldMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/queries.js?macro=true";
import { default as reviewCo1LgC3tSWMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/review.vue?macro=true";
import { default as changejXU0ApkCynMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change.vue?macro=true";
import { default as mutationsa6VEyeHyKEMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/mutations.js?macro=true";
import { default as queriescaafJWiHiEMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/queries.js?macro=true";
import { default as effectiveDateniG0r4oC1AMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/effectiveDate.vue?macro=true";
import { default as mutations3CCd2VDGl1Meta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/mutations.js?macro=true";
import { default as queries1nAzfGeozNMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/queries.js?macro=true";
import { default as reviewl1rmyqXV1nMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/review.vue?macro=true";
import { default as reschedule8v65xlUSBUMeta } from "/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule.vue?macro=true";
import { default as contactsIeHvtY173LMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/contacts.vue?macro=true";
import { default as createClaimPayload1WXsKQbmWEMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/createClaimPayload.js?macro=true";
import { default as damagehz8bhfCV8bMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/damage.vue?macro=true";
import { default as reviewk0mU5C66kCMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim/review.vue?macro=true";
import { default as file_45claimY9a74dauqFMeta } from "/vercel/path1/pages/policy/[policyNumber]/file-claim.vue?macro=true";
import { default as activityEfWYhTbCz8Meta } from "/vercel/path1/pages/policy/[policyNumber]/index/activity.vue?macro=true";
import { default as claimsF9RrccaNJfMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/claims.vue?macro=true";
import { default as contactbwwc1OPSw0Meta } from "/vercel/path1/pages/policy/[policyNumber]/index/contact.vue?macro=true";
import { default as documentsN4keVHWXwlMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/documents.vue?macro=true";
import { default as indexhidKqG2U6xMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/index.vue?macro=true";
import { default as mutationsZ4lH5tmc8KMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/mutations.js?macro=true";
import { default as notesdv4TdbMsFqMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/notes.vue?macro=true";
import { default as queriesyIj1Hd2GKcMeta } from "/vercel/path1/pages/policy/[policyNumber]/index/queries.js?macro=true";
import { default as index79GEQtc70PMeta } from "/vercel/path1/pages/policy/[policyNumber]/index.vue?macro=true";
import { default as _91policyNumber_93IPiHThzZsCMeta } from "/vercel/path1/pages/policy/[policyNumber].vue?macro=true";
import { default as indexTj4RXnma2JMeta } from "/vercel/path1/pages/search/index.vue?macro=true";
import { default as queriesJH0jcTi8YMMeta } from "/vercel/path1/pages/search/queries.js?macro=true";
import { default as agency_45detailsh11mvzPEXYMeta } from "/vercel/path1/pages/settings/index/agency-details.vue?macro=true";
import { default as index4r0iIvQZYgMeta } from "/vercel/path1/pages/settings/index/index.vue?macro=true";
import { default as integrationsUm1aIcQSTHMeta } from "/vercel/path1/pages/settings/index/integrations.vue?macro=true";
import { default as mutationsC0AXSqD2UcMeta } from "/vercel/path1/pages/settings/index/mutations.js?macro=true";
import { default as queriesQU37PJeK0mMeta } from "/vercel/path1/pages/settings/index/queries.js?macro=true";
import { default as _91userID_93kH02LVmxhKMeta } from "/vercel/path1/pages/settings/index/team/[userID].vue?macro=true";
import { default as indexxH3MYhsmsuMeta } from "/vercel/path1/pages/settings/index/team/index.vue?macro=true";
import { default as teamNlU4fkX8EUMeta } from "/vercel/path1/pages/settings/index/team.vue?macro=true";
import { default as indexRDl0kZWQMpMeta } from "/vercel/path1/pages/settings/index.vue?macro=true";
import { default as indexVz5EpmTqq2Meta } from "/vercel/path1/pages/upcoming-renewals/index.vue?macro=true";
export default [
  {
    name: authenticateEQDX3yONJFMeta?.name ?? "authenticate",
    path: authenticateEQDX3yONJFMeta?.path ?? "/authenticate",
    meta: authenticateEQDX3yONJFMeta || {},
    alias: authenticateEQDX3yONJFMeta?.alias || [],
    redirect: authenticateEQDX3yONJFMeta?.redirect,
    component: () => import("/vercel/path1/pages/authenticate.vue").then(m => m.default || m)
  },
  {
    name: eligible_45quotesojKzolFuAyMeta?.name ?? "eligible-quotes",
    path: eligible_45quotesojKzolFuAyMeta?.path ?? "/eligible-quotes",
    meta: eligible_45quotesojKzolFuAyMeta || {},
    alias: eligible_45quotesojKzolFuAyMeta?.alias || [],
    redirect: eligible_45quotesojKzolFuAyMeta?.redirect,
    component: () => import("/vercel/path1/pages/eligible-quotes.vue").then(m => m.default || m)
  },
  {
    name: indexJ7d6BfZ2vfMeta?.name ?? "non-renewals",
    path: indexJ7d6BfZ2vfMeta?.path ?? "/non-renewals",
    meta: indexJ7d6BfZ2vfMeta || {},
    alias: indexJ7d6BfZ2vfMeta?.alias || [],
    redirect: indexJ7d6BfZ2vfMeta?.redirect,
    component: () => import("/vercel/path1/pages/non-renewals/index.vue").then(m => m.default || m)
  },
  {
    name: queriesceuKTTyZj1Meta?.name ?? "non-renewals-queries",
    path: queriesceuKTTyZj1Meta?.path ?? "/non-renewals/queries",
    meta: queriesceuKTTyZj1Meta || {},
    alias: queriesceuKTTyZj1Meta?.alias || [],
    redirect: queriesceuKTTyZj1Meta?.redirect,
    component: () => import("/vercel/path1/pages/non-renewals/queries.js").then(m => m.default || m)
  },
  {
    name: pinguATbf1KJ9FMeta?.name ?? "ping",
    path: pinguATbf1KJ9FMeta?.path ?? "/ping",
    meta: pinguATbf1KJ9FMeta || {},
    alias: pinguATbf1KJ9FMeta?.alias || [],
    redirect: pinguATbf1KJ9FMeta?.redirect,
    component: () => import("/vercel/path1/pages/ping.vue").then(m => m.default || m)
  },
  {
    name: _91policyNumber_93IPiHThzZsCMeta?.name ?? undefined,
    path: _91policyNumber_93IPiHThzZsCMeta?.path ?? "/policy/:policyNumber()",
    meta: _91policyNumber_93IPiHThzZsCMeta || {},
    alias: _91policyNumber_93IPiHThzZsCMeta?.alias || [],
    redirect: _91policyNumber_93IPiHThzZsCMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber].vue").then(m => m.default || m),
    children: [
  {
    name: agentOfRecordYlnKY4QwuXMeta?.name ?? "policy-policyNumber-termID-transactionID-agentOfRecord",
    path: agentOfRecordYlnKY4QwuXMeta?.path ?? ":termID()/:transactionID()/agentOfRecord",
    meta: agentOfRecordYlnKY4QwuXMeta || {},
    alias: agentOfRecordYlnKY4QwuXMeta?.alias || [],
    redirect: agentOfRecordYlnKY4QwuXMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord.vue").then(m => m.default || m),
    children: [
  {
    name: mutationsAU8dzzcWSNMeta?.name ?? "policy-policyNumber-termID-transactionID-agentOfRecord-mutations",
    path: mutationsAU8dzzcWSNMeta?.path ?? "mutations",
    meta: mutationsAU8dzzcWSNMeta || {},
    alias: mutationsAU8dzzcWSNMeta?.alias || [],
    redirect: mutationsAU8dzzcWSNMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/mutations.js").then(m => m.default || m)
  },
  {
    name: queriesZAVqeQFWbfMeta?.name ?? "policy-policyNumber-termID-transactionID-agentOfRecord-queries",
    path: queriesZAVqeQFWbfMeta?.path ?? "queries",
    meta: queriesZAVqeQFWbfMeta || {},
    alias: queriesZAVqeQFWbfMeta?.alias || [],
    redirect: queriesZAVqeQFWbfMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/queries.js").then(m => m.default || m)
  },
  {
    name: reviewg7b80jytSkMeta?.name ?? "policy-policyNumber-termID-transactionID-agentOfRecord-review",
    path: reviewg7b80jytSkMeta?.path ?? "review",
    meta: reviewg7b80jytSkMeta || {},
    alias: reviewg7b80jytSkMeta?.alias || [],
    redirect: reviewg7b80jytSkMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/review.vue").then(m => m.default || m)
  },
  {
    name: update2E6og3zUe1Meta?.name ?? "policy-policyNumber-termID-transactionID-agentOfRecord-update",
    path: update2E6og3zUe1Meta?.path ?? "update",
    meta: update2E6og3zUe1Meta || {},
    alias: update2E6og3zUe1Meta?.alias || [],
    redirect: update2E6og3zUe1Meta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/agentOfRecord/update.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cancellationYsqHMa69P4Meta?.name ?? undefined,
    path: cancellationYsqHMa69P4Meta?.path ?? ":termID()/:transactionID()/cancellation",
    meta: cancellationYsqHMa69P4Meta || {},
    alias: cancellationYsqHMa69P4Meta?.alias || [],
    redirect: cancellationYsqHMa69P4Meta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation.vue").then(m => m.default || m),
    children: [
  {
    name: cancellationContextlvZzxAH8RSMeta?.name ?? "policy-policyNumber-termID-transactionID-cancellation-cancellationContext",
    path: cancellationContextlvZzxAH8RSMeta?.path ?? "cancellationContext",
    meta: cancellationContextlvZzxAH8RSMeta || {},
    alias: cancellationContextlvZzxAH8RSMeta?.alias || [],
    redirect: cancellationContextlvZzxAH8RSMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/cancellationContext.js").then(m => m.default || m)
  },
  {
    name: index3cIVfKwk2mMeta?.name ?? "policy-policyNumber-termID-transactionID-cancellation",
    path: index3cIVfKwk2mMeta?.path ?? "",
    meta: index3cIVfKwk2mMeta || {},
    alias: index3cIVfKwk2mMeta?.alias || [],
    redirect: index3cIVfKwk2mMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/index.vue").then(m => m.default || m)
  },
  {
    name: reviewWSdyAcSmOIMeta?.name ?? "policy-policyNumber-termID-transactionID-cancellation-review",
    path: reviewWSdyAcSmOIMeta?.path ?? "review",
    meta: reviewWSdyAcSmOIMeta || {},
    alias: reviewWSdyAcSmOIMeta?.alias || [],
    redirect: reviewWSdyAcSmOIMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/cancellation/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: changejXU0ApkCynMeta?.name ?? "policy-policyNumber-termID-transactionID-change",
    path: changejXU0ApkCynMeta?.path ?? ":termID()/:transactionID()/change",
    meta: changejXU0ApkCynMeta || {},
    alias: changejXU0ApkCynMeta?.alias || [],
    redirect: changejXU0ApkCynMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change.vue").then(m => m.default || m),
    children: [
  {
    name: billingwnf8kXLpaLMeta?.name ?? "policy-policyNumber-termID-transactionID-change-billing",
    path: billingwnf8kXLpaLMeta?.path ?? "billing",
    meta: billingwnf8kXLpaLMeta || {},
    alias: billingwnf8kXLpaLMeta?.alias || [],
    redirect: billingwnf8kXLpaLMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/billing.vue").then(m => m.default || m)
  },
  {
    name: mortgagee0xy2JsaZYNMeta?.name ?? "policy-policyNumber-termID-transactionID-change-mortgagee",
    path: mortgagee0xy2JsaZYNMeta?.path ?? "mortgagee",
    meta: mortgagee0xy2JsaZYNMeta || {},
    alias: mortgagee0xy2JsaZYNMeta?.alias || [],
    redirect: mortgagee0xy2JsaZYNMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mortgagee.vue").then(m => m.default || m)
  },
  {
    name: mutationsdhkCvBB6JlMeta?.name ?? "policy-policyNumber-termID-transactionID-change-mutations",
    path: mutationsdhkCvBB6JlMeta?.path ?? "mutations",
    meta: mutationsdhkCvBB6JlMeta || {},
    alias: mutationsdhkCvBB6JlMeta?.alias || [],
    redirect: mutationsdhkCvBB6JlMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/mutations.js").then(m => m.default || m)
  },
  {
    name: partiesIM3C33wVdFMeta?.name ?? "policy-policyNumber-termID-transactionID-change-parties",
    path: partiesIM3C33wVdFMeta?.path ?? "parties",
    meta: partiesIM3C33wVdFMeta || {},
    alias: partiesIM3C33wVdFMeta?.alias || [],
    redirect: partiesIM3C33wVdFMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/parties.vue").then(m => m.default || m)
  },
  {
    name: partiesStateFactory1075NKa2DOMeta?.name ?? "policy-policyNumber-termID-transactionID-change-partiesStateFactory",
    path: partiesStateFactory1075NKa2DOMeta?.path ?? "partiesStateFactory",
    meta: partiesStateFactory1075NKa2DOMeta || {},
    alias: partiesStateFactory1075NKa2DOMeta?.alias || [],
    redirect: partiesStateFactory1075NKa2DOMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/partiesStateFactory.js").then(m => m.default || m)
  },
  {
    name: queriesM0NKHxmJldMeta?.name ?? "policy-policyNumber-termID-transactionID-change-queries",
    path: queriesM0NKHxmJldMeta?.path ?? "queries",
    meta: queriesM0NKHxmJldMeta || {},
    alias: queriesM0NKHxmJldMeta?.alias || [],
    redirect: queriesM0NKHxmJldMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/queries.js").then(m => m.default || m)
  },
  {
    name: reviewCo1LgC3tSWMeta?.name ?? "policy-policyNumber-termID-transactionID-change-review",
    path: reviewCo1LgC3tSWMeta?.path ?? "review",
    meta: reviewCo1LgC3tSWMeta || {},
    alias: reviewCo1LgC3tSWMeta?.alias || [],
    redirect: reviewCo1LgC3tSWMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/change/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: mutationsa6VEyeHyKEMeta?.name ?? "policy-policyNumber-termID-transactionID-mutations",
    path: mutationsa6VEyeHyKEMeta?.path ?? ":termID()/:transactionID()/mutations",
    meta: mutationsa6VEyeHyKEMeta || {},
    alias: mutationsa6VEyeHyKEMeta?.alias || [],
    redirect: mutationsa6VEyeHyKEMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/mutations.js").then(m => m.default || m)
  },
  {
    name: queriescaafJWiHiEMeta?.name ?? "policy-policyNumber-termID-transactionID-queries",
    path: queriescaafJWiHiEMeta?.path ?? ":termID()/:transactionID()/queries",
    meta: queriescaafJWiHiEMeta || {},
    alias: queriescaafJWiHiEMeta?.alias || [],
    redirect: queriescaafJWiHiEMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/queries.js").then(m => m.default || m)
  },
  {
    name: reschedule8v65xlUSBUMeta?.name ?? "policy-policyNumber-termID-transactionID-reschedule",
    path: reschedule8v65xlUSBUMeta?.path ?? ":termID()/:transactionID()/reschedule",
    meta: reschedule8v65xlUSBUMeta || {},
    alias: reschedule8v65xlUSBUMeta?.alias || [],
    redirect: reschedule8v65xlUSBUMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule.vue").then(m => m.default || m),
    children: [
  {
    name: effectiveDateniG0r4oC1AMeta?.name ?? "policy-policyNumber-termID-transactionID-reschedule-effectiveDate",
    path: effectiveDateniG0r4oC1AMeta?.path ?? "effectiveDate",
    meta: effectiveDateniG0r4oC1AMeta || {},
    alias: effectiveDateniG0r4oC1AMeta?.alias || [],
    redirect: effectiveDateniG0r4oC1AMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/effectiveDate.vue").then(m => m.default || m)
  },
  {
    name: mutations3CCd2VDGl1Meta?.name ?? "policy-policyNumber-termID-transactionID-reschedule-mutations",
    path: mutations3CCd2VDGl1Meta?.path ?? "mutations",
    meta: mutations3CCd2VDGl1Meta || {},
    alias: mutations3CCd2VDGl1Meta?.alias || [],
    redirect: mutations3CCd2VDGl1Meta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/mutations.js").then(m => m.default || m)
  },
  {
    name: queries1nAzfGeozNMeta?.name ?? "policy-policyNumber-termID-transactionID-reschedule-queries",
    path: queries1nAzfGeozNMeta?.path ?? "queries",
    meta: queries1nAzfGeozNMeta || {},
    alias: queries1nAzfGeozNMeta?.alias || [],
    redirect: queries1nAzfGeozNMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/queries.js").then(m => m.default || m)
  },
  {
    name: reviewl1rmyqXV1nMeta?.name ?? "policy-policyNumber-termID-transactionID-reschedule-review",
    path: reviewl1rmyqXV1nMeta?.path ?? "review",
    meta: reviewl1rmyqXV1nMeta || {},
    alias: reviewl1rmyqXV1nMeta?.alias || [],
    redirect: reviewl1rmyqXV1nMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/[termID]/[transactionID]/reschedule/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: file_45claimY9a74dauqFMeta?.name ?? "policy-policyNumber-file-claim",
    path: file_45claimY9a74dauqFMeta?.path ?? "file-claim",
    meta: file_45claimY9a74dauqFMeta || {},
    alias: file_45claimY9a74dauqFMeta?.alias || [],
    redirect: file_45claimY9a74dauqFMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim.vue").then(m => m.default || m),
    children: [
  {
    name: contactsIeHvtY173LMeta?.name ?? "policy-policyNumber-file-claim-contacts",
    path: contactsIeHvtY173LMeta?.path ?? "contacts",
    meta: contactsIeHvtY173LMeta || {},
    alias: contactsIeHvtY173LMeta?.alias || [],
    redirect: contactsIeHvtY173LMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/contacts.vue").then(m => m.default || m)
  },
  {
    name: createClaimPayload1WXsKQbmWEMeta?.name ?? "policy-policyNumber-file-claim-createClaimPayload",
    path: createClaimPayload1WXsKQbmWEMeta?.path ?? "createClaimPayload",
    meta: createClaimPayload1WXsKQbmWEMeta || {},
    alias: createClaimPayload1WXsKQbmWEMeta?.alias || [],
    redirect: createClaimPayload1WXsKQbmWEMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/createClaimPayload.js").then(m => m.default || m)
  },
  {
    name: damagehz8bhfCV8bMeta?.name ?? "policy-policyNumber-file-claim-damage",
    path: damagehz8bhfCV8bMeta?.path ?? "damage",
    meta: damagehz8bhfCV8bMeta || {},
    alias: damagehz8bhfCV8bMeta?.alias || [],
    redirect: damagehz8bhfCV8bMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/damage.vue").then(m => m.default || m)
  },
  {
    name: reviewk0mU5C66kCMeta?.name ?? "policy-policyNumber-file-claim-review",
    path: reviewk0mU5C66kCMeta?.path ?? "review",
    meta: reviewk0mU5C66kCMeta || {},
    alias: reviewk0mU5C66kCMeta?.alias || [],
    redirect: reviewk0mU5C66kCMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/file-claim/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index79GEQtc70PMeta?.name ?? undefined,
    path: index79GEQtc70PMeta?.path ?? "",
    meta: index79GEQtc70PMeta || {},
    alias: index79GEQtc70PMeta?.alias || [],
    redirect: index79GEQtc70PMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index.vue").then(m => m.default || m),
    children: [
  {
    name: activityEfWYhTbCz8Meta?.name ?? "policy-policyNumber-index-activity",
    path: activityEfWYhTbCz8Meta?.path ?? "activity",
    meta: activityEfWYhTbCz8Meta || {},
    alias: activityEfWYhTbCz8Meta?.alias || [],
    redirect: activityEfWYhTbCz8Meta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/activity.vue").then(m => m.default || m)
  },
  {
    name: claimsF9RrccaNJfMeta?.name ?? "policy-policyNumber-index-claims",
    path: claimsF9RrccaNJfMeta?.path ?? "claims",
    meta: claimsF9RrccaNJfMeta || {},
    alias: claimsF9RrccaNJfMeta?.alias || [],
    redirect: claimsF9RrccaNJfMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/claims.vue").then(m => m.default || m)
  },
  {
    name: contactbwwc1OPSw0Meta?.name ?? "policy-policyNumber-index-contact",
    path: contactbwwc1OPSw0Meta?.path ?? "contact",
    meta: contactbwwc1OPSw0Meta || {},
    alias: contactbwwc1OPSw0Meta?.alias || [],
    redirect: contactbwwc1OPSw0Meta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/contact.vue").then(m => m.default || m)
  },
  {
    name: documentsN4keVHWXwlMeta?.name ?? "policy-policyNumber-index-documents",
    path: documentsN4keVHWXwlMeta?.path ?? "documents",
    meta: documentsN4keVHWXwlMeta || {},
    alias: documentsN4keVHWXwlMeta?.alias || [],
    redirect: documentsN4keVHWXwlMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/documents.vue").then(m => m.default || m)
  },
  {
    name: indexhidKqG2U6xMeta?.name ?? "policy-policyNumber-index",
    path: indexhidKqG2U6xMeta?.path ?? "",
    meta: indexhidKqG2U6xMeta || {},
    alias: indexhidKqG2U6xMeta?.alias || [],
    redirect: indexhidKqG2U6xMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/index.vue").then(m => m.default || m)
  },
  {
    name: mutationsZ4lH5tmc8KMeta?.name ?? "policy-policyNumber-index-mutations",
    path: mutationsZ4lH5tmc8KMeta?.path ?? "mutations",
    meta: mutationsZ4lH5tmc8KMeta || {},
    alias: mutationsZ4lH5tmc8KMeta?.alias || [],
    redirect: mutationsZ4lH5tmc8KMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/mutations.js").then(m => m.default || m)
  },
  {
    name: notesdv4TdbMsFqMeta?.name ?? "policy-policyNumber-index-notes",
    path: notesdv4TdbMsFqMeta?.path ?? "notes",
    meta: notesdv4TdbMsFqMeta || {},
    alias: notesdv4TdbMsFqMeta?.alias || [],
    redirect: notesdv4TdbMsFqMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/notes.vue").then(m => m.default || m)
  },
  {
    name: queriesyIj1Hd2GKcMeta?.name ?? "policy-policyNumber-index-queries",
    path: queriesyIj1Hd2GKcMeta?.path ?? "queries",
    meta: queriesyIj1Hd2GKcMeta || {},
    alias: queriesyIj1Hd2GKcMeta?.alias || [],
    redirect: queriesyIj1Hd2GKcMeta?.redirect,
    component: () => import("/vercel/path1/pages/policy/[policyNumber]/index/queries.js").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexTj4RXnma2JMeta?.name ?? "search",
    path: indexTj4RXnma2JMeta?.path ?? "/search",
    meta: indexTj4RXnma2JMeta || {},
    alias: indexTj4RXnma2JMeta?.alias || [],
    redirect: indexTj4RXnma2JMeta?.redirect,
    component: () => import("/vercel/path1/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: queriesJH0jcTi8YMMeta?.name ?? "search-queries",
    path: queriesJH0jcTi8YMMeta?.path ?? "/search/queries",
    meta: queriesJH0jcTi8YMMeta || {},
    alias: queriesJH0jcTi8YMMeta?.alias || [],
    redirect: queriesJH0jcTi8YMMeta?.redirect,
    component: () => import("/vercel/path1/pages/search/queries.js").then(m => m.default || m)
  },
  {
    name: indexRDl0kZWQMpMeta?.name ?? undefined,
    path: indexRDl0kZWQMpMeta?.path ?? "/settings",
    meta: indexRDl0kZWQMpMeta || {},
    alias: indexRDl0kZWQMpMeta?.alias || [],
    redirect: indexRDl0kZWQMpMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index.vue").then(m => m.default || m),
    children: [
  {
    name: agency_45detailsh11mvzPEXYMeta?.name ?? "settings-index-agency-details",
    path: agency_45detailsh11mvzPEXYMeta?.path ?? "agency-details",
    meta: agency_45detailsh11mvzPEXYMeta || {},
    alias: agency_45detailsh11mvzPEXYMeta?.alias || [],
    redirect: agency_45detailsh11mvzPEXYMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/agency-details.vue").then(m => m.default || m)
  },
  {
    name: index4r0iIvQZYgMeta?.name ?? "settings-index",
    path: index4r0iIvQZYgMeta?.path ?? "",
    meta: index4r0iIvQZYgMeta || {},
    alias: index4r0iIvQZYgMeta?.alias || [],
    redirect: index4r0iIvQZYgMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsUm1aIcQSTHMeta?.name ?? "settings-index-integrations",
    path: integrationsUm1aIcQSTHMeta?.path ?? "integrations",
    meta: integrationsUm1aIcQSTHMeta || {},
    alias: integrationsUm1aIcQSTHMeta?.alias || [],
    redirect: integrationsUm1aIcQSTHMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/integrations.vue").then(m => m.default || m)
  },
  {
    name: mutationsC0AXSqD2UcMeta?.name ?? "settings-index-mutations",
    path: mutationsC0AXSqD2UcMeta?.path ?? "mutations",
    meta: mutationsC0AXSqD2UcMeta || {},
    alias: mutationsC0AXSqD2UcMeta?.alias || [],
    redirect: mutationsC0AXSqD2UcMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/mutations.js").then(m => m.default || m)
  },
  {
    name: queriesQU37PJeK0mMeta?.name ?? "settings-index-queries",
    path: queriesQU37PJeK0mMeta?.path ?? "queries",
    meta: queriesQU37PJeK0mMeta || {},
    alias: queriesQU37PJeK0mMeta?.alias || [],
    redirect: queriesQU37PJeK0mMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/queries.js").then(m => m.default || m)
  },
  {
    name: teamNlU4fkX8EUMeta?.name ?? undefined,
    path: teamNlU4fkX8EUMeta?.path ?? "team",
    meta: teamNlU4fkX8EUMeta || {},
    alias: teamNlU4fkX8EUMeta?.alias || [],
    redirect: teamNlU4fkX8EUMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/team.vue").then(m => m.default || m),
    children: [
  {
    name: _91userID_93kH02LVmxhKMeta?.name ?? "settings-index-team-userID",
    path: _91userID_93kH02LVmxhKMeta?.path ?? ":userID()",
    meta: _91userID_93kH02LVmxhKMeta || {},
    alias: _91userID_93kH02LVmxhKMeta?.alias || [],
    redirect: _91userID_93kH02LVmxhKMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/team/[userID].vue").then(m => m.default || m)
  },
  {
    name: indexxH3MYhsmsuMeta?.name ?? "settings-index-team",
    path: indexxH3MYhsmsuMeta?.path ?? "",
    meta: indexxH3MYhsmsuMeta || {},
    alias: indexxH3MYhsmsuMeta?.alias || [],
    redirect: indexxH3MYhsmsuMeta?.redirect,
    component: () => import("/vercel/path1/pages/settings/index/team/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexVz5EpmTqq2Meta?.name ?? "upcoming-renewals",
    path: indexVz5EpmTqq2Meta?.path ?? "/upcoming-renewals",
    meta: indexVz5EpmTqq2Meta || {},
    alias: indexVz5EpmTqq2Meta?.alias || [],
    redirect: indexVz5EpmTqq2Meta?.redirect,
    component: () => import("/vercel/path1/pages/upcoming-renewals/index.vue").then(m => m.default || m)
  }
]
import { gql } from 'graphql-tag'

export const GET_TRANSACTION_PARTIES = gql`query GetTransactionParties($number: String!, $termId: Int!, $transactionId: Int!) {
  transaction(policyNumber: $number, termID: $termId, transactionID: $transactionId) {
    id
    termID
    termEffectiveDate
    coverage {
      primaryUse
    }
    humanNamedInsureds {
      firstName
      middleName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
    legalEntityNamedInsureds {
      name
      type
      earnsOtherRevenue
      streetLine1
      streetLine2
      city
      state
      zip
      isPrimaryContact
    }
    nonResidentTrustees {
      firstName
      lastName
      birthdate
      streetLine1
      streetLine2
      city
      state
      zip
    }
    otherHouseholdMembers {
      firstName
      lastName
      birthdate
      relationship
    }
    additionalInsureds {
      firstName
      lastName
      streetLine1
      streetLine2
      city
      state
      zip
      description
    }
    additionalInterests {
      name
      streetLine1
      streetLine2
      city
      state
      zip
      description
    }
    thirdPartyDesignees {
      thirdPartyDesigneeUUID
      name
      streetLine1
      streetLine2
      city
      state
      zip
    }
  }
}`

export const GET_PAYMENT_INFO = gql`query GetPaymentInfo($number: String!, $termId: Int!, $transactionId: Int!) {
  transaction(policyNumber: $number, termID: $termId, transactionID: $transactionId) {
    id
    paymentMethod,
    mortgagees {
      name
      streetLine1
      streetLine2
      city
      state
      zipCode
      plusFourCode
      loanNumber
      fax
      email
      interest
    }
    billingMortgagee {
      name,
      streetLine1,
      streetLine2,
      city,
      state,
      zipCode,
      plusFourCode,
      loanNumber,
      fax,
      email
    }
  }
}`

export const GET_MORTGAGEES = gql`query GetMortgagees($number: String!, $termId: Int!, $transactionId: Int!) {
  transaction(policyNumber: $number, termID: $termId, transactionID: $transactionId) {
    id
    issued
    paymentMethod
    mortgagees {
      name
      streetLine1
      streetLine2
      city
      state
      zipCode
      plusFourCode
      loanNumber
      fax
      email
      interest
    }
    billingMortgagee {
      name,
      streetLine1,
      streetLine2,
      city,
      state,
      zipCode,
      plusFourCode,
      loanNumber,
      fax,
      email
    }
  }
}`

import { gql } from 'graphql-tag'

export const GET_EFFECTIVE_DATE_TRANSACTIONS = gql`query Transaction($number: String!, $termId: Int!, $transactionId: Int!, $orderBy: allowedTransactionOrderBy) {
  transaction(policyNumber: $number, termID: $termId, transactionID: $transactionId) {
    id
    termID
    termEffectiveDate
    transactionEffectiveDate
    annualPremiumCents
    proratedPremiumCents
    replacementCostDollars
    issued
  }
  policy(number: $number) {
    id
    createdAt
    initialEffectiveDate
    currentTermLastActiveTransaction {
      id
      action
      transactionEffectiveDate
      annualPremiumCents
      proratedPremiumCents
      replacementCostDollars
    }
    transactions(orderBy: $orderBy) {
      id
      termID
      effectiveDate
      isReversed
    }
  }
}`

import { gql } from 'graphql-tag'

export const UPDATE_USER = gql`mutation updateUser($data: UserInput!) {
  updateUser(data: $data) {
    id
    email
    firstName
    lastName
    roles
    states
    isActive
  }
}`

export const UPDATE_USER_PASSWORD = gql`mutation updateUserPassword($data: UpdateUserPasswordInput!) {
  updateUserPassword(data: $data)
}`

export const UPDATE_AGENCY = gql`mutation updateAgency($data: UpdateAgencyInput) {
  updateAgency(data: $data) {
    id
    name
    notificationEmail
    reportsEmail
    commissionsEmail
    extraCommissionsEmails
    email
    phone
    website
    streetLine1
    streetLine2
    city
    state
    zip
  }
}`

export const ADD_NEW_USER = gql`mutation CreateUser($data: CreateUserInput) {
  createUser(data: $data) {
    id
    firstName
    lastName
    email
    roles
    states
    isActive
  }
}
`

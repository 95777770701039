/**
* Requests the user's feature flags and evaluates the state of all features provided.
* Expects a list of parameters where each is a string that matches the key for a feature flag.
*/
export const RequireFeatureFlags = function() {
  const featureFlags = [...arguments]

  return ({ $featureFlags }) => featureFlags.every(featureFlag => $featureFlags.useFlag(featureFlag).value)
}

/**
* References the `self` store to evaluate permissions.
* Expects a list of parameters where each is a string that matches the key for a permission.
*/
export const RequirePermissions = function() {
  const permissions = [...arguments]

  return ({ $store }) => {
    return permissions.every(permission => $store?.state?.self?.permissions?.[permission])
  }
}

/**
* References the `self` store to evaluate legacy roles.
* Expects a list of parameters where each is a string that matches the key for a legacy role.
*/
export const RequireRoles = function() {
  const roles = [...arguments]

  return ({ $store }) => {
    return roles.every(permission => $store?.state?.self?.roles?.[permission])
  }
}

import { gql } from 'graphql-tag'

export const UPDATE_EFFECTIVE_DATE = gql`mutation UpdateEffectiveDate($data: UpdateEffectiveDateInput!) {
  updateEffectiveDate(data: $data) {
    id
    transactionEffectiveDate
    annualPremiumCents
    proratedPremiumCents
    replacementCostDollars
  }
}`

import { gql } from 'graphql-tag'

export const GET_AGENCY = gql`query Query($agencyId: String!) {
  agency(agencyID: $agencyId) {
    id
    users {
      id
      firstName
      lastName
      email
      roles
      states
      isActive
    }
    appointedStates
    principalEmail
  }
}`

export const GET_USER = gql`query User($userId: String!) {
  user(id: $userId) {
    firstName
    id
    email
    lastName
    isActive
    roles
    states
    agency {
      id
      appointedStates
    }
  }
}`
